<template>
  <div class="body">
    <div style="width: 100%; min-height: 850px; background: #edeff2">
      <div class="body_left">
        <div class="body_left_top">
          部门/分组管理
          <a @click="openOne">管理</a>
        </div>
        <a-menu mode="inline" :default-selected-keys="selectedKeys">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="menu(item.id, item.code)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="body_table">
        <div class="body_table_top">
          内部人员管理
          <a-button type="primary" @click="add">+新建</a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="tableList"
          class="table1"
          :pagination="false"
        >
          <template slot="operation" slot-scope="text, record, index">
            <a @click="edit(record)">编辑</a>&ensp;&ensp;
            <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="del(record.id)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </a-table>
        <!-- 分页 -->
        <div
          style="
            display: block;
            width: 100%;
            height: 50px;
            text-align: center;
            margin-top: 45px;
          "
        >
          <a-pagination
            :current="pageNo1"
            :total="total"
            :pageSize="pageSize1"
            show-quick-jumper
            @change="PageNOchange"
            style="display: inline-block"
          />
        </div>
      </div>
    </div>
    <!-- 一级菜单管理 -->
    <a-modal
      @cancel="Onecancel"
      title="部门/分组管理"
      :visible="OneVisible"
      width="760px"
      :footer="null"
    >
      <a-table
        :columns="invoice"
        class="table"
        :data-source="dataSource"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return record.id;
          }
        "
      >
        <span slot="name_hhh" slot-scope="text, record">
          <a-input
            v-if="record.editable"
            v-model="record.name"
            placeholder="请输入"
            width="240px"
          />
          <template v-else>
            <diV
              style="
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ record.name }}
            </diV>
          </template>
        </span>
        <template slot="operation" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a style="margin-right: 5px" @click="() => saveOne(record)"
                >保存</a
              >
              <a-popconfirm
                title="确认取消?"
                @confirm="() => cancelOne(record)"
              >
                <a style="margin-right: 5px">取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a style="margin-right: 5px" @click="() => editOne(record)"
                >编辑</a
              >
              <a-popconfirm
                title="确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delOne(record.code)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
      <div class="button" @click="addOne">+ 添加</div>
    </a-modal>
    <!-- 创建人员弹窗 -->
    <a-modal
      @cancel="cancel"
      title="新建/编辑"
      :visible="visible"
      width="460px"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="form.phone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="负责事务" prop="remark">
          <a-textarea v-model="form.remark" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getGroup,
  addGroup,
  delGroup,
  editGroup,
  addMember,
  getMember,
  delMember,
  editMember,
} from "@/service/meetingExecutionPeopleManagement_api.js";
export default {
  data() {
    return {
      type: "insider",
      pageNo: 1,
      pageSize: 999,
      pageNo1: 1,
      pageSize1: 10,
      total: 0,
      menuList: [],
      dataSource: [],
      tableList: [],
      OneVisible: false,
      menuId: "",
      menuCode: "",
      selectedKeys: [],
      invoice: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name_hhh" },
          width: "70%",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      visible: false,
      form: {},
      visibleType: "add",
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: "13%",
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone",
          width: "15%",
        },
        {
          title: "负责事务",
          dataIndex: "remark",
          key: "remark",
          width: "20%",
        },
        {
          title: "创建人",
          dataIndex: "createdByName",
          key: "createdByName",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          key: "createdTime",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        remark: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.load();
    this.selectedKeys[0]=0
  },
  methods: {
    async load() {
      const res = await getGroup(this.type, this.pageNo, this.pageSize);
      if (res.code == 0) {
        this.menuList = res.data;
        if (this.menuId == "" && this.menuCode == "") {
          // 默认获取第一个
          this.menuId = this.menuList[0].id;
          this.menuCode = this.menuList[0].code;
          this.menu(this.menuId, this.menuCode);
        }
      }
    },
    Onecancel() {
      (this.OneVisible = false), this.load();
    },
    // 打开一级菜单
    async openOne() {
      this.OneVisible = true;
      const res = await getGroup(this.type, this.pageNo, this.pageSize);
      if (res.code == 0) {
        this.dataSource = res.data;
      }
    },
    addOne() {
      const editable = this.dataSource.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newa = {
        parentId: 0,
        id: Math.random(),
        name: undefined,
        type: this.type,
        editable: true,
        key: Math.random(),
      };
      this.dataSource.push(newa);
    },
    async saveOne(record) {
      if (
        record.name == undefined ||
        record.name == "" ||
        record.name == null
      ) {
        this.$message.warn("请输入名称！");
        return;
      }
      if (record.key) {
        let data = {
          name: record.name,
          parentId: record.parentId,
          type: this.type,
        };
        const res = await addGroup(data);
        if (res.code == 0) {
          this.$message.success("保存成功！");
          this.openOne();
        }
      }
      if (!record.key) {
        let data = {
          name: record.name,
          code: record.code,
        };
        const res = await editGroup(data);
        if (res.code == 0) {
          this.$message.success("保存成功！");
          this.openOne();
        }
      }
    },
    async delOne(code) {
      const res = await delGroup(code);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.openOne();
      }
    },
    async editOne(record) {
      const editable = this.dataSource.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newData = [...this.dataSource];
      const target = newData.find((item) => record.id === item.id);
      if (target) {
        target.editable = true;
        this.dataSource = newData;
      }
    },
    async cancelOne(record) {
      this.openOne();
    },
    async menu(id, code) {
      this.menuId = id;
      this.menuCode = code;
      let data = {
        code: this.menuCode,
        memberType: this.type,
        pageNo: this.pageNo1,
        pageSize: this.pageSize1,
      };
      const res = await getMember(data);
      if (res.code == 0) {
        this.tableList = res.data;
        this.total = res.count;
      }
    },
    add() {
      this.visible = true;
    },
    async handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.visibleType == "add") {
            let data = {
              name: this.form.name,
              phone: this.form.phone,
              memberType: this.type, //供应商：supplier，内部人员：insider，志愿者：volunteer
              groupCode: this.menuCode, //所属分组code
              remark: this.form.remark, //备注
            };
            const res = await addMember(data);
            if (res.code == 0) {
              this.$message.success("创建成功！");
              this.visible = false;
              this.visibleType = "add";
              this.menu(this.menuId, this.menuCode);
              this.$refs.ruleForm.resetFields();
            } else {
              this.$message.warn(res.message);
            }
          } else {
            let data = {
              id: this.form.id,
              name: this.form.name,
              phone: this.form.phone,
              memberType: this.type, //供应商：supplier，内部人员：insider，志愿者：volunteer
              groupCode: this.menuCode, //所属分组code
              remark: this.form.remark, //备注
            };
            const res = await editMember(data);
            if (res.code == 0) {
              this.$message.success("编辑成功！");
              this.visible = false;
              this.visibleType = "add";
              this.menu(this.menuId, this.menuCode);
              this.$refs.ruleForm.resetFields();
            } else {
              this.$message.warn(res.message);
            }
          }
        }
      });
    },
    cancel() {
      this.visible = false;
      this.form = {};
      this.$refs.ruleForm.resetFields();
    },
    async del(id) {
      const res = await delMember(id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.menu(this.menuId, this.menuCode);
      }
    },
    async edit(record) {
      this.menu(this.menuId, this.menuCode);
      this.form = record;
      this.visible = true;
      this.visibleType = "edit";
    },
    PageNOchange(pageNo, pageSize) {
      this.pageNo1 = pageNo;
      this.menu(this.menuId, this.menuCode);
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  width: 100%;
  min-height: 850px;
  //  background-color: #473636;
  border: 10px solid #edeff2;

  //  display: flex;
}
.body_left {
  width: 15%;
  background: white;
  min-height: 850px;
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;
  //  border-right:  10px solid  #edeff2;
  .body_left_top {
    height: 50px;
    width: 100%;
    line-height: 50px;
    padding: 0px 15px 0px 10px;
    border-bottom: 1px solid #edeff2;
    font-size: 14px;
    color: rgb(37, 36, 36);
    display: flex;
    justify-content: space-between;
  }
}
.body_table {
  width: 84%;
  display: inline-block;
  min-height: 850px;
  background: white;
  vertical-align: top;
  .body_table_top {
    height: 50px;
    width: 97%;
    margin: 0 auto;
    line-height: 50px;
    // padding: 0px 15px 0px 10px;
    border-bottom: 1px solid #edeff2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: $topTitleColor;
  }
}
.button {
  width: 95%;
  margin: 0 auto;
  height: 40px;
  margin-top: 10px;
  text-align: center;
  line-height: 40px;
  border: 1.5px dashed #c8cacd;
  border-radius: 15px;
  //  color: black;
}
.table1 {
  width: 98%;
  margin: 10px auto;
}
</style>
